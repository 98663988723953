import { createContext, Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react';
import { MarkerIcon, VesselType } from 'src/types';
import L, { IconOptions } from 'leaflet';

const iconHeight = 53;
const iconWidth = 14;
const iconCenterX = 7;
const iconCenterY = iconHeight / 2;

export const defaultIconOptions: IconOptions = {
  iconRetinaUrl: './src/assets/leaflet-icons/vessel-marker@2x.png',
  iconUrl: './src/assets/leaflet-icons/vessel-marker.png',
  iconSize: [iconWidth, iconHeight],
  iconAnchor: [iconCenterX, iconCenterY],
  popupAnchor: [0, -iconHeight / 2],
  className: 'marker-vessel-icon',
  shadowUrl: undefined,
};

export const defaultIcon = L.icon(defaultIconOptions);

export const activeIcon = L.icon({
  iconRetinaUrl: './src/assets/leaflet-icons/vessel-marker-active@2x.png',
  iconUrl: './src/assets/leaflet-icons/vessel-marker-active.png',
  iconSize: [iconWidth, iconHeight],
  iconAnchor: [iconCenterX, iconCenterY],
  popupAnchor: [0, -iconHeight / 2],
  className: 'marker-vessel-icon marker-vessel-icon-active',
  shadowUrl: undefined,
});

const defaultMarkerIcons: MarkerIcon[] = Object.keys(VesselType).map((type) => ({
  type,
  color: 'white',
  iconWidth: 14,
  iconHeight: 53,
}));

type IconContextType = {
  defaultIcon: L.Icon;
  activeIcon: L.Icon;
  markerIcons: MarkerIcon[];
  setMarkerIcons: Dispatch<SetStateAction<MarkerIcon[]>>;
};

const initialData: IconContextType = {
  defaultIcon: defaultIcon,
  activeIcon: activeIcon,
  markerIcons: defaultMarkerIcons,
  setMarkerIcons: () => {
    console.log('Icons not updated');
  },
};

export const MarkerIconContext = createContext<IconContextType>(initialData);

export const MarkerIconProvider = ({ children }: { children: ReactNode }) => {
  const savedIcons = localStorage.getItem('marker-icon-props');
  const initialState = savedIcons ? JSON.parse(savedIcons) : defaultMarkerIcons;

  const [markerIcons, setMarkerIcons] = useState<MarkerIcon[]>(initialState);

  const value = useMemo(
    () => ({ defaultIcon, activeIcon, markerIcons, setMarkerIcons }),
    [markerIcons]
  );

  return <MarkerIconContext.Provider value={value}>{children}</MarkerIconContext.Provider>;
};
