import { MaressVesselType } from 'src/types';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ProjectSchemaType } from '@/src/types/schemas/projects/project';

const defaultIconWidth = 50; //14
const defaultIconLength = 100; //53
const defaultIconCenterX = defaultIconWidth / 2;
const defaultIconCenterY = defaultIconLength / 2;
const defaultLightColor = '#D5DEE1';
const defaultDarkColor = '#75838E';
const defaultMapZoom = 9;

export type IconSettings = {
  [vesselType: string]: {
    darkColor: string;
    lightColor: string;
    iconWidth: number;
    iconLength: number;
    iconCenterX: number;
    iconCenterY: number;
  };
};

type MarkerSettingsContextType = {
  setProject: Dispatch<SetStateAction<ProjectSchemaType | null>>;
  defaultSettings: IconSettings[string];
  iconSettings: IconSettings;
  setIconSettings: Dispatch<SetStateAction<IconSettings>>;
  getDefaultMarkerSettings: (project: ProjectSchemaType | null) => IconSettings;
  defaultMapZoom: number;
};

const defaultSettings = {
  darkColor: defaultDarkColor,
  lightColor: defaultLightColor,
  iconWidth: defaultIconWidth,
  iconLength: defaultIconLength,
  iconCenterX: defaultIconCenterX,
  iconCenterY: defaultIconCenterY,
};

export function getProjectVesselTypes(project: ProjectSchemaType | null) {
  if (project == null) return [];
  return project.vessels.map((vessel) => ({
    type: vessel.vessel.vesselType?.type,
    color: vessel.vessel.vesselType?.color,
  }));
}

export function getDefaultMarkerSettings(project: ProjectSchemaType | null) {
  const projectVesselTypes: MaressVesselType[] = getProjectVesselTypes(project);
  return projectVesselTypes.reduce((acc, curr) => {
    if (!curr.type) return acc;
    acc[curr.type] = { ...defaultSettings, lightColor: curr.color ?? defaultLightColor };
    return acc;
  }, {} as IconSettings);
}

const initialState: MarkerSettingsContextType = {
  setProject: () => {
    console.log('setProject is not implemented');
  },
  defaultSettings,
  iconSettings: {},
  setIconSettings: () => {
    console.log('setMarkerSettings is not implemented');
  },
  getDefaultMarkerSettings: () => {
    return {};
  },
  defaultMapZoom: defaultMapZoom,
};

export const MarkerSettingsContext = createContext<MarkerSettingsContextType>(initialState);

const MarkerSettingsProvider = ({ children }: { children: ReactNode }) => {
  const [project, setProject] = useState<ProjectSchemaType | null>(null);
  const [iconSettings, setIconSettings] = useState<IconSettings>(initialState.iconSettings);

  useEffect(() => {
    if (project == null) return;
    const vesselTypes = getProjectVesselTypes(project);

    const storedMarkerSettings = localStorage.getItem('marker-settings');
    if (storedMarkerSettings != null) {
      const input: IconSettings = JSON.parse(storedMarkerSettings);

      // Add settings for a vessel type if not included in the custom settings
      for (const vesselType of vesselTypes) {
        if (input[vesselType.type as string] === undefined) {
          input[vesselType.type as string] = {
            ...defaultSettings,
            lightColor: vesselType.color ?? defaultLightColor,
          };
        }
      }

      setIconSettings(input);
      localStorage.setItem('marker-settings', JSON.stringify(input));
    } else {
      console.log('Saving initial marker settings...');
      const initialSettings = getProjectVesselTypes(project).reduce((acc, curr) => {
        if (!curr.type) return acc;
        const typeMaressColor = curr.color ?? defaultLightColor;
        acc[curr.type] = { ...defaultSettings, lightColor: typeMaressColor };
        return acc;
      }, {} as IconSettings);

      setIconSettings(initialSettings);
      localStorage.setItem('marker-settings', JSON.stringify(initialSettings));
    }
  }, [project]);

  useEffect(() => {
    //   const item = localStorage.getItem('custom-marker-settings');
    //   const storedSettings: IconSettings = item != null ? JSON.parse(item) : null;
    // const zoomPercentageChange = 1 + (mapZoom - defaultMapZoom) / defaultMapZoom;
    // // When the map zoom is less than the default zoom, the marker size reduces
    // setMarkerScaleFactor(zoomPercentageChange);
    // for (const value of Object.values(storedSettings)) {
    //   value.iconWidth = value.iconWidth * zoomPercentageChange;
    //   value.iconLength = value.iconLength * zoomPercentageChange;
    // }
    // setIconSettings(storedSettings);
    // console.log(defaultState);
  }, []);

  const value = useMemo(
    () => ({
      setProject,
      defaultSettings,
      iconSettings,
      setIconSettings,
      getDefaultMarkerSettings,
      defaultMapZoom,
    }),
    [iconSettings]
  );

  return <MarkerSettingsContext.Provider value={value}>{children}</MarkerSettingsContext.Provider>;
};

export default MarkerSettingsProvider;
