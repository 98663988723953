import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectSchemaType } from '@/src/types/schemas/projects/project';
import useProject from '@/src/hooks/useProject';
import { VesselSchemaType } from '@/src/types/schemas/projects/vessel';
import { getDayjs, getDurationInUnit } from '@/src/utils/dateHelper';
import { ActivityEstimationSchemaType } from '@/src/types/schemas/projects/vesselActivityEstimation';
import { PeriodSchemaType } from '@/src/types/schemas/projects/vesselPeriod';
import { SeriesChartData } from 'src/types';
import { Dayjs } from 'dayjs';
import { KeyedMutator } from 'swr';

export type ProjectContextType = {
  projectId: string | undefined;
  project: ProjectSchemaType | null;
  vesselId: string | undefined;
  vessel: VesselSchemaType | null;
  setProject: Dispatch<SetStateAction<ProjectSchemaType | null>>;
  getVesselById: (vesselId: VesselSchemaType['id']) => VesselSchemaType | undefined;
  getVesselEstimatedFuelMtBetweenDates: ({
    vesselId,
    startDate,
    endDate,
  }: {
    vesselId: VesselSchemaType['id'];
    startDate: string | undefined;
    endDate: string | undefined;
  }) => number;
  getVesselsEstimatedFuelMtBetweenDates: ({
    startDate,
    endDate,
  }: {
    startDate: string | undefined;
    endDate: string | undefined;
  }) => number;
  getTransformedActivityEstimatedFuelMtTotalSeriesBetweenDates: ({
    startDate,
    endDate,
  }: {
    startDate: string | undefined;
    endDate: string | undefined;
  }) => SeriesChartData[];
  isLoading: boolean;
  isLoadingVessel: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  optimisticUpdateVessels: ({
    newVessels,
  }: {
    newVessels: ProjectSchemaType['vessels'];
  }) => Promise<void>;
  optimisticDeleteVessel: ({ vesselId }: { vesselId: VesselSchemaType['id'] }) => Promise<void>;
  optimisticAddPeriod: ({
    vesselId,
    period,
  }: {
    vesselId: VesselSchemaType['id'];
    period: PeriodSchemaType;
  }) => Promise<void>;
  optimisticDeletePeriod: ({
    vesselId,
    periodId,
  }: {
    periodId: PeriodSchemaType['id'];
    vesselId: VesselSchemaType['id'];
  }) => Promise<void>;
  optimisticUpdatePeriod: ({
    vesselId,
    period,
  }: {
    period: PeriodSchemaType;
    vesselId: VesselSchemaType['id'];
  }) => Promise<void>;
  optimisticUpdateActivityEstimations: ({
    vesselId,
    activityEstimations,
  }: {
    activityEstimations: ActivityEstimationSchemaType[];
    vesselId: VesselSchemaType['id'];
  }) => Promise<void>;
  timeStaticSettings: TimeStaticSettingsType;
  timeActiveDay: number;
  setTimeActiveDay: Dispatch<SetStateAction<number>>;
  timeActiveDate: string;
  mutate: KeyedMutator<ProjectSchemaType>;
};

type TimeStaticSettingsType = {
  now: Dayjs;
  today: number;
  totalDuration: number;
  currentDuration: number;
  startDate: string;
  endDate: string;
} | null;

const useProjectContext = () => {
  const { projectId, vesselId } = useParams();
  const { data, isLoading: isLoadingProject, mutate } = useProject({ projectId });
  const [vessel, setVessel] = useState<VesselSchemaType | null>(null);
  const [isLoadingVessel, setIsLoadingVessel] = useState(false);
  const [timeActiveDay, setTimeActiveDay] = useState(0);

  // Vessel
  useEffect(() => {
    async function asyncFunction() {
      setIsLoadingVessel(true);
      if (!data || !vesselId) return setVessel(null);
      const currentVessel = await data?.vessels?.find((v) => v.id === vesselId);
      setVessel(currentVessel ?? null);
    }

    asyncFunction().then(() => {
      setIsLoadingVessel(false);
    });
  }, [data, vesselId]);

  const getVesselById = useCallback(
    (vesselId: string) => {
      return data?.vessels?.find((v) => v.id === vesselId);
    },
    [data?.vessels]
  );

  const getVesselEstimatedDailyFuelById = useCallback(
    ({ vesselId }: { vesselId: string }) => {
      const vessel = getVesselById(vesselId);
      const activityEstimations = vessel?.activityEstimations;

      return activityEstimations
        ? activityEstimations.reduce(
            (acc: { percentage: number; fuel: number }, item) => {
              const value = item.value || 0;
              const fuel = item.fuel || 0;
              acc.percentage = parseFloat((acc.percentage + value).toFixed(2));
              acc.fuel = parseFloat((acc.fuel + (value / 100) * fuel).toFixed(2));
              return acc;
            },
            { percentage: 0, fuel: 0 }
          )
        : { percentage: 0, fuel: 0 };
    },
    [getVesselById]
  );

  const getVesselActiveDaysBetweenDates = useCallback(
    ({
      vesselId,
      startDate,
      endDate,
    }: {
      vesselId: string;
      startDate: string;
      endDate: string;
    }) => {
      const vessel = getVesselById(vesselId);
      if (!vessel) return 0;

      return vessel.activePeriods?.reduce((acc, period) => {
        const isPeriodBetweenDates =
          getDayjs(period.startDate).isBetween(startDate, endDate, null, '[]') &&
          getDayjs(period.endDate).isBetween(startDate, endDate, null, '[]');
        const isPeriodBeforeStartDate = getDayjs(period.startDate).isSameOrBefore(startDate);
        const isPeriodAfterEndDate = getDayjs(period.endDate).isSameOrAfter(endDate);
        const isPeriodBeforeStartDateAndAfterEndDate =
          isPeriodBeforeStartDate && isPeriodAfterEndDate;
        if (isPeriodBetweenDates) {
          return (
            acc +
            getDurationInUnit({ startDate: period.startDate, endDate: period.endDate })
              .totalDuration
          );
        }
        if (isPeriodBeforeStartDateAndAfterEndDate) {
          return acc + getDurationInUnit({ startDate, endDate }).totalDuration;
        }
        if (isPeriodBeforeStartDate && !isPeriodAfterEndDate) {
          return acc + getDurationInUnit({ startDate, endDate: period.endDate }).totalDuration;
        }
        if (!isPeriodBeforeStartDate && isPeriodAfterEndDate) {
          return acc + getDurationInUnit({ startDate: period.startDate, endDate }).totalDuration;
        }
        return acc;
      }, 0);
    },
    [getVesselById]
  );

  const getVesselEstimatedFuelMtBetweenDates = useCallback(
    ({
      startDate,
      endDate,
      vesselId,
    }: {
      startDate: string | undefined;
      endDate: string | undefined;
      vesselId: string;
    }) => {
      const vessel = getVesselById(vesselId);
      if (!vessel || !startDate || !endDate) return 0;

      const dailyFuel = getVesselEstimatedDailyFuelById({ vesselId });
      const activeDaysBetweenDates = getVesselActiveDaysBetweenDates({
        vesselId,
        startDate,
        endDate,
      });
      return (dailyFuel.fuel || 0) * (activeDaysBetweenDates || 0);
    },
    [getVesselById, getVesselEstimatedDailyFuelById, getVesselActiveDaysBetweenDates]
  );

  const getVesselsEstimatedFuelMtBetweenDates = useCallback(
    ({ startDate, endDate }: { startDate: string | undefined; endDate: string | undefined }) => {
      const vessels = data?.vessels;
      if (!vessels || !startDate || !endDate) return 0;
      return vessels.reduce((acc, cur) => {
        const dailyFuel = getVesselEstimatedDailyFuelById({ vesselId: cur.id });
        const activeDaysBetweenDates = getVesselActiveDaysBetweenDates({
          vesselId: cur.id,
          startDate,
          endDate,
        });
        const curVesselEstimatedFuelTotal = (dailyFuel.fuel || 0) * (activeDaysBetweenDates || 0);
        return acc + curVesselEstimatedFuelTotal;
      }, 0);
    },
    [data?.vessels, getVesselEstimatedDailyFuelById, getVesselActiveDaysBetweenDates]
  );
  //getTransformedActivityDataTotalActualSeriesBetweenDates
  const getTransformedActivityEstimatedFuelMtTotalSeriesBetweenDates = useCallback(
    ({ startDate, endDate }: { startDate: string | undefined; endDate: string | undefined }) => {
      const series: SeriesChartData[] = [];
      const vessels = data?.vessels;
      if (!vessels || !startDate || !endDate) return series;
      vessels.forEach((vessel) => {
        const activeDaysBetweenDates = getVesselActiveDaysBetweenDates({
          vesselId: vessel.id,
          startDate,
          endDate,
        });
        vessel.activityEstimations?.forEach((activity) => {
          const existing = series.find((s) => s.name === activity.activityType);
          const value = (activity.value / 100) * (activity.fuel || 0) * activeDaysBetweenDates;
          if (existing) {
            existing.value += value;
          } else {
            series.push({
              name: activity.activityType,
              value,
              series: 'Forecasted',
            });
          }
        });
      });
      // only return series with value > 0
      return series.filter((s) => s.value > 0);
    },
    [data?.vessels, getVesselActiveDaysBetweenDates]
  );

  // Time
  const timeStaticSettings: TimeStaticSettingsType = useMemo(() => {
    const startDate = data?.startDate;
    const endDate = data?.endDate;
    if (!startDate || !endDate) return null;
    const now = getDayjs();
    const isNowBeforeStart = now.isBefore(getDayjs(startDate));
    const { currentDuration, totalDuration } = getDurationInUnit({ startDate, endDate });
    const today = isNowBeforeStart ? -1 : currentDuration; // -1 means before start, disabling waves forecasting
    return {
      now,
      today,
      totalDuration,
      currentDuration,
      startDate,
      endDate,
    };
  }, [data?.startDate, data?.endDate]);

  useEffect(() => {
    setTimeActiveDay(timeStaticSettings?.currentDuration ?? 0);
  }, [timeStaticSettings?.currentDuration]);

  const timeActiveDate = useMemo(() => {
    if (!data?.startDate) return null;
    return getDayjs(data?.startDate).add(timeActiveDay, 'day').format();
  }, [timeActiveDay, data?.startDate]);

  // Optimistic updates
  const optimisticUpdateVessels: ProjectContextType['optimisticUpdateVessels'] = async ({
    newVessels,
  }) => {
    if (data === undefined || newVessels.length === 0) return;
    await mutate(
      { ...data, vessels: newVessels },
      {
        optimisticData: { ...data, vessels: newVessels },
        rollbackOnError: true,
        populateCache: true,
        revalidate: false,
      }
    );
  };

  const optimisticDeleteVessel: ProjectContextType['optimisticDeleteVessel'] = async ({
    vesselId,
  }) => {
    if (!data || !vesselId) return;
    const newVessels = data?.vessels?.filter((vessel) => vessel.id !== vesselId);
    await mutate(
      { ...data, vessels: newVessels },
      {
        optimisticData: { ...data, vessels: newVessels },
        rollbackOnError: true,
        populateCache: true,
        revalidate: false,
      }
    );
  };

  const optimisticAddPeriod: ProjectContextType['optimisticAddPeriod'] = async ({
    vesselId,
    period,
  }) => {
    if (!data || !vesselId) return;
    const newVessels = data?.vessels?.map((vessel) => {
      if (vessel.id === vesselId) {
        return {
          ...vessel,
          activePeriods: [...vessel.activePeriods, period],
        };
      }
      return vessel;
    });
    await mutate(
      { ...data, vessels: newVessels },
      {
        optimisticData: { ...data, vessels: newVessels },
        rollbackOnError: true,
        populateCache: true,
        revalidate: false,
      }
    );
  };

  const optimisticDeletePeriod: ProjectContextType['optimisticDeletePeriod'] = async ({
    vesselId,
    periodId,
  }) => {
    if (!data || !vesselId) return;
    const newVessels = data?.vessels?.map((vessel) => {
      if (vessel.id === vesselId) {
        return {
          ...vessel,
          activePeriods: vessel.activePeriods.filter((period) => period.id !== periodId),
        };
      }
      return vessel;
    });
    await mutate(
      { ...data, vessels: newVessels },
      {
        optimisticData: { ...data, vessels: newVessels },
        rollbackOnError: true,
        populateCache: true,
        revalidate: false,
      }
    );
  };

  const optimisticUpdatePeriod: ProjectContextType['optimisticUpdatePeriod'] = async ({
    vesselId,
    period,
  }) => {
    if (!data || !vesselId) return;
    const newVessels = data?.vessels?.map((vessel) => {
      if (vessel.id === vesselId) {
        return {
          ...vessel,
          activePeriods: vessel.activePeriods.map((p) => {
            if (p.id === period.id) {
              return period;
            }
            return p;
          }),
        };
      }
      return vessel;
    });
    await mutate(
      { ...data, vessels: newVessels },
      {
        optimisticData: { ...data, vessels: newVessels },
        rollbackOnError: true,
        populateCache: true,
        revalidate: false,
      }
    );
  };

  const optimisticUpdateActivityEstimations: ProjectContextType['optimisticUpdateActivityEstimations'] =
    async ({ vesselId, activityEstimations }) => {
      if (!data || !vesselId) return;
      const newVessels = data?.vessels?.map((vessel) => {
        if (vessel.id === vesselId) {
          return {
            ...vessel,
            activityEstimations,
          };
        }
        return vessel;
      });
      await mutate(
        { ...data, vessels: newVessels },
        {
          optimisticData: { ...data, vessels: newVessels },
          rollbackOnError: true,
          populateCache: true,
          revalidate: false,
        }
      );
    };

  return {
    projectId,
    project: data,
    vesselId,
    vessel,
    getVesselById,
    getVesselEstimatedFuelMtBetweenDates,
    getVesselsEstimatedFuelMtBetweenDates,
    getTransformedActivityEstimatedFuelMtTotalSeriesBetweenDates,
    optimisticUpdateVessels,
    optimisticDeleteVessel,
    optimisticAddPeriod,
    optimisticDeletePeriod,
    optimisticUpdatePeriod,
    optimisticUpdateActivityEstimations,
    isLoading: isLoadingProject,
    isLoadingVessel,
    timeStaticSettings,
    timeActiveDay,
    setTimeActiveDay,
    timeActiveDate,
    mutate,
  };
};

export default useProjectContext;
