import projectsApi from '@/src/lib/projectsApi';
import useSWRImmutable from 'swr/immutable';
import { errorInterceptor } from '@/src/utils/errorHelper';
import { StaticActivitiesSchemaType } from '@/src/types/schemas/statics/activities';

export default function useStaticActivities({ shouldFetch }: { shouldFetch: boolean }) {
  const { data, error, isLoading } = useSWRImmutable(
    shouldFetch ? `api/types/activities` : null,
    // url is not used, but is needed for the cache key
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (url): Promise<StaticActivitiesSchemaType> => projectsApi.getStaticActivities(),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Never retry on 404.
        const err = errorInterceptor(error);
        if (err?.status === 404) return;

        // Only retry up to 5 times.
        if (retryCount >= 5) return;

        // Retry after 5 seconds.
        setTimeout(() => revalidate({ retryCount }), 5000);
      },
    }
  );

  return {
    isLoading,
    error,
    data,
  };
}
