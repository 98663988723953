import { useAuthContext } from '@/contexts/auth';
import {
  StaticActivitiesSchema,
  StaticActivitiesSchemaType,
  StaticActivitySchemaType,
} from '@/src/types/schemas/statics/activities';
import { ACTIVITIES_KEY } from '@/src/constants/localStorageKeys';
import useStaticActivities from '@/src/hooks/useStaticActivities';
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';

export interface StaticsContextData {
  activities: StaticActivitiesSchemaType | null;
  isLoading: boolean;
  getStaticActivityById: (id: StaticActivitySchemaType['id']) => StaticActivitySchemaType | null;
  getStaticActivityByName: (
    name: StaticActivitySchemaType['name']
  ) => StaticActivitySchemaType | null;
  getFormattedStaticActivityById: (
    id: StaticActivitySchemaType['id']
  ) => { name: string; color: string } | null;
  getFormattedStaticActivityByName: (
    name: StaticActivitySchemaType['name']
  ) => { name: string; color: string } | null;
}

const StaticsContext = createContext<StaticsContextData>({} as StaticsContextData);

export const StaticsProvider = ({ children }: { children: ReactNode }) => {
  const [activities, setActivities] = useState<StaticActivitiesSchemaType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isAuthed } = useAuthContext();
  const shouldFetch = isAuthed && !isLoading && !activities;
  const { isLoading: isLoadingActivities, data: dataActivities } = useStaticActivities({
    shouldFetch,
  });

  // initial load check local storage
  useEffect(() => {
    async function loadStorageData() {
      const storageActivities = localStorage.getItem(ACTIVITIES_KEY);

      if (storageActivities) {
        const json = JSON.parse(storageActivities);

        const parse = StaticActivitiesSchema.safeParse(json);
        if (parse.success && parse.data !== undefined) {
          setActivities(parse.data);
        }
      }
      setIsLoading(false);
    }

    void loadStorageData();
  }, []);

  useEffect(() => {
    async function setStorageData() {
      if (!isLoadingActivities && dataActivities) {
        localStorage.setItem(ACTIVITIES_KEY, JSON.stringify(dataActivities));
        setIsLoading(false);
      }
    }
    void setStorageData();
  }, [isAuthed, isLoadingActivities, dataActivities]);

  const getStaticActivityById: StaticsContextData['getStaticActivityById'] = (id) =>
    activities?.find((a) => a.id === id) ?? null;

  const getStaticActivityByName: StaticsContextData['getStaticActivityByName'] = (name) =>
    activities?.find((a) => a.name === name) ?? null;

  const getFormattedStaticActivityById: StaticsContextData['getFormattedStaticActivityById'] = (
    id
  ) => {
    const activity = getStaticActivityById(id);
    if (!activity) return null;
    const { name, projectColor, mgoColor, lngColor } = activity;
    return {
      name: name ?? 'Unknown',
      color: projectColor ?? mgoColor ?? lngColor ?? 'hotpink', // hotpink is fallback to make sure it's visible
    };
  };

  const getFormattedStaticActivityByName: StaticsContextData['getFormattedStaticActivityByName'] = (
    name
  ) => {
    const activity = getStaticActivityByName(name);
    if (!activity) return null;
    const { projectColor, mgoColor, lngColor } = activity;
    return {
      name: name ?? 'Unknown',
      color: projectColor ?? mgoColor ?? lngColor ?? 'hotpink',
    };
  };

  const value = useMemo(
    () => ({
      activities,
      isLoading,
      getStaticActivityById,
      getStaticActivityByName,
      getFormattedStaticActivityById,
      getFormattedStaticActivityByName,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activities, isLoading]
  );

  return <StaticsContext.Provider value={value}>{children}</StaticsContext.Provider>;
};

StaticsContext.displayName = 'StaticsContext';

export const useStaticsContext = () => {
  return useContext(StaticsContext);
};
